<template>
  <div>
    <div
      v-for="({ title, type, value, isCopyable }, index) in data"
      :key="title"
      :class="{ 'q-mt-md': index > 0 }"
    >
      <div v-if="type === 'qr'">
        <q-img
          :src="value"
          :style="{ maxWidth: '100px' }"
          contain
          class="q-mx-auto block"
        />

        <h3 class="text-center text-subtitle2 text-blue-grey q-mb-none q-mt-sm">
          Scan the QR code or copy the wallet number
        </h3>
      </div>

      <p v-else class="q-mb-none flex no-wrap items-center text-subtitle2">
        <b class="text-weight-bold text-primary text-no-wrap"> {{ title }}: </b>

        <span class="ellipsis inline-block q-ml-md"> {{ value }} </span>

        <q-btn
          v-if="isCopyable"
          flat
          round
          size="sm"
          color="primary"
          icon="mdi-content-copy"
          class="q-ml-sm"
          @click="copyText(value)"
        >
          <q-tooltip
            :offset="[5, 5]"
            anchor="top middle"
            self="bottom middle"
            delay="100"
            hide-delay="100"
          >
            Copy
          </q-tooltip>
        </q-btn>
      </p>
    </div>
  </div>
</template>

<script>
import { Notify } from 'quasar';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    copyText(value) {
      navigator.clipboard.writeText(value);
      Notify.create({
        type: 'info',
        message: 'Copied',
        timeout: 1e3,
        actions: [
          {
            color: 'white',
            icon: 'mdi-close',
            fabMini: true,
            size: 'xs',
          },
        ],
      });
    },
  },
};
</script>
